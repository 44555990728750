@import "~less-variables";

.page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 100%;
    gap: 15px;
    padding: 15px;
    background-color: @scoreboard-bg;
    color: #fff;
    overflow-y: hidden;
    background-color: @scoreboard-row-bg;

    .title {
        margin: 0;
        font-size: 16px;
        text-wrap-style: balance;
    }
    .qrCode {
        height: 240px;
    }
}

.pageFit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 100vh;
    gap: 7vh;
    padding: 5vw;
    background-color: @scoreboard-bg;
    color: #fff;
    overflow-y: hidden;

    .title {
        margin: 0;
        font-size: 7vh;
        font-weight: @font-weight-bold;
        text-wrap-style: balance;
    }

    .qrCode {
        height: 70vh;
    }
}
